<template>
    <div class="player">
        <div class="player-controls">
            <div class="bar-container">
                <div v-on:click="seek" class="player-progress" title="Time played : Total time">
                    <div :style="{ width: this.percentComplete + '%' }" class="player-seeker"></div>
                    <div :style="marginLeft" class="player-seeker-button"></div>
                </div>
                <div class="player-time">
                    <div class="player-time-current">{{ currentTime }}</div>
                    <div class="player-time-total">{{ durationTime }}</div>
                </div>
            </div>

            <div class="controls">
                <div>
                    <a v-on:click.prevent="fastBack" @keyup.space="fastBack" title="15 Sekunden zurück" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 30 30"><path fill="currentColor" d="M9.2 15.25v-3.9h-.85q-.25 0-.412-.175-.163-.175-.163-.425t.163-.425q.162-.175.437-.175h1.15q.35 0 .612.262.263.263.263.638v4.225q0 .225-.162.4-.163.175-.438.175-.25 0-.425-.175T9.2 15.25Zm3.4.6q-.425 0-.712-.288-.288-.287-.288-.712v-3.7q0-.425.288-.712.287-.288.712-.288h1.875q.425 0 .713.288.287.287.287.712v3.7q0 .425-.287.712-.288.288-.713.288Zm.3-1.2h1.275q.05 0 .088-.025.037-.025.037-.075v-3.1q0-.05-.037-.075-.038-.025-.088-.025H12.9q-.05 0-.075.025-.025.025-.025.075v3.1q0 .05.025.075.025.025.075.025Zm-7.875-.975q.25 2.7 2.238 4.513Q9.25 20 12 20q2.925 0 4.962-2.038Q19 15.925 19 13t-2.038-4.963Q14.925 6 12 6h-.05v1.225q0 .325-.287.437-.288.113-.513-.112l-1.975-2q-.15-.125-.15-.312 0-.188.15-.313l1.975-2q.225-.225.513-.113.287.113.287.438V4.5H12q1.775 0 3.312.675Q16.85 5.85 18 7q1.15 1.15 1.825 2.688.675 1.537.675 3.312t-.675 3.312Q19.15 17.85 18 19q-1.15 1.15-2.688 1.825-1.537.675-3.312.675-3.35 0-5.75-2.2t-2.7-5.5q-.025-.325.2-.562.225-.238.575-.238.275 0 .475.188.2.187.225.487Z"/></svg>
                    </a>
                </div>

                <div>
                    <a v-on:click.prevent="playing = !playing" @keyup.space="playing = !playing" title="Play/Pause" href="#">
                        <svg  height="35" width="35" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path v-if="!playing" fill="currentColor" d="M15,10.001c0,0.299-0.305,0.514-0.305,0.514l-8.561,5.303C5.51,16.227,5,15.924,5,15.149V4.852c0-0.777,0.51-1.078,1.135-0.67l8.561,5.305C14.695,9.487,15,9.702,15,10.001z"/>
                            <path v-else fill="currentColor" d="M15,3h-2c-0.553,0-1,0.048-1,0.6v12.8c0,0.552,0.447,0.6,1,0.6h2c0.553,0,1-0.048,1-0.6V3.6C16,3.048,15.553,3,15,3z M7,3H5C4.447,3,4,3.048,4,3.6v12.8C4,16.952,4.447,17,5,17h2c0.553,0,1-0.048,1-0.6V3.6C8,3.048,7.553,3,7,3z"/>
                        </svg>
                    </a>
                </div>

                <div>
                    <a v-on:click.prevent="fastForward" @keyup.space="fastForward" title="15 Sekunden weiter" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 30 30"><path fill="currentColor" d="M9.8 15.85q-.25 0-.425-.175T9.2 15.25v-3.9h-.825q-.275 0-.437-.175-.163-.175-.163-.425t.163-.425q.162-.175.437-.175H9.5q.375 0 .637.262.263.263.263.638v4.2q0 .25-.162.425-.163.175-.438.175Zm2.8 0q-.425 0-.712-.288-.288-.287-.288-.712v-3.7q0-.425.288-.712.287-.288.712-.288h1.875q.425 0 .713.288.287.287.287.712v3.7q0 .425-.287.712-.288.288-.713.288Zm.3-1.2h1.275q.05 0 .088-.025.037-.025.037-.075v-3.1q0-.05-.037-.075-.038-.025-.088-.025H12.9q-.05 0-.075.025-.025.025-.025.075v3.1q0 .05.025.075.025.025.075.025ZM12 21.5q-1.775 0-3.312-.675Q7.15 20.15 6 19q-1.15-1.15-1.825-2.688Q3.5 14.775 3.5 13t.675-3.312Q4.85 8.15 6 7q1.15-1.15 2.688-1.825Q10.225 4.5 12 4.5h.275l-1.1-1.075q-.2-.225-.225-.513-.025-.287.225-.537.25-.25.55-.25.3 0 .525.225l2.275 2.25q.125.15.188.3.062.15.062.35 0 .15-.062.312-.063.163-.188.313l-2.25 2.25q-.225.225-.537.212-.313-.012-.538-.212-.225-.225-.225-.538 0-.312.225-.537L12.275 6H12Q9.075 6 7.038 8.037 5 10.075 5 13q0 2.925 2.038 4.962Q9.075 20 12 20q2.725 0 4.712-1.8 1.988-1.8 2.238-4.45.025-.325.25-.538.225-.212.55-.212.325 0 .538.225.212.225.187.525-.275 3.275-2.7 5.512Q15.35 21.5 12 21.5Z"/></svg>
                    </a>
                </div>
            </div>
        </div>
        <audio preload="metadata" :loop="innerLoop" ref="audiofile" :src="file" style="display: none;"></audio>
    </div>
</template>

<script>
    export default {
        name: "AudioPlayer",
        props: {
            file: {
                type: String,
                default: null
            },
            autoPlay: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                audio: undefined,
                currentSeconds: 0,
                durationSeconds: 0,
                innerLoop: false,
                loaded: false,
                playing: false,
                previousVolume: 35,
                showVolume: false,
                volume: 100
            }
        },
        computed: {
            marginLeft(){
             return 'margin-left:'+this.percentComplete + '%';
         },
         currentTime() {
            return this.convertTimeHHMMSS(this.currentSeconds);
        },
        durationTime() {
            return this.convertTimeHHMMSS(this.durationSeconds);
        },
        percentComplete() {
            return parseInt(this.currentSeconds / this.durationSeconds * 100);
        },
        muted() {
            return this.volume / 100 === 0;
        }
    },
    watch: {
        playing(value) {
            if (value) { return this.audio.play(); }
            this.audio.pause();
        },
        volume(value) {
            this.showVolume = false;
            this.audio.volume = this.volume / 100;
        }
    },
    methods: {
       convertTimeHHMMSS(val) {
        let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
        return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
    download() {
        this.stop();
        window.open(this.file, 'download');
    },
    load() {
        if (this.audio.readyState >= 2) {
            this.loaded = true;
            this.durationSeconds = parseInt(this.audio.duration);
            return this.playing = this.autoPlay;
        }

        throw new Error('Failed to load sound file.');
    },
    mute() {
        if (this.muted) {
            return this.volume = this.previousVolume;
        }

        this.previousVolume = this.volume;
        this.volume = 0;
    },
    seek(e) {
        return;
        if (!this.playing || e.target.tagName === 'SPAN') {
            return;
        }

        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.audio.currentTime = parseInt(this.audio.duration * seekPos);
    },
    fastBack() {
        this.audio.currentTime = parseInt(this.audio.currentTime - 10);
    },
    fastForward(e) {
        this.audio.currentTime = parseInt(this.audio.currentTime + 10);
    },
    stop() {
        this.playing = false;
        this.audio.currentTime = 0;
    },
    update(e) {
        this.currentSeconds = parseInt(this.audio.currentTime);
    }
},
created() {
    this.innerLoop = this.loop;
},
mounted() {
    this.audio = this.$el.querySelectorAll('audio')[0];
    this.audio.addEventListener('timeupdate', this.update);
    this.audio.addEventListener('loadeddata', this.load);
    this.audio.addEventListener('pause', () => { this.playing = false; });
    this.audio.addEventListener('play', () => { this.playing = true; });
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    $player-color: #E30059;

    .player-controls {
        align-items: center;
        max-width: 100%;

        > div {

            &:last-child {
                border-right: none;
            }

            a {
                color: $player-color;
                display: block;
                line-height: 0;
                padding: 0 0.5em 0.5em 0.5em;
                text-decoration: none;
                margin-right: 20px;

                @media(max-width: 991px) {
                    padding: 0.5rem;
                }
            }
        }
    }

    .controls {
        display: flex;
        justify-content: center;
    }

    .player-progress {
        background-color: lightgrey;
        cursor: pointer;
        height: 4px;
        margin: 10px 10px;
        border-radius: 20px;
        min-width: 300px;
        position: relative;
        margin-top: 34px;

        @media(max-width: 400px){
            min-width: 100px;
        }

        @media (min-width:401px) and (max-width: 600px){
            min-width: 150px;
        }

        .player-seeker {
            top: 0;
            left: 0;
            bottom: 0;
            position: absolute;
            border-radius: 20px;
            background-color: $player-color;
        }

        .player-seeker-button{
            top: -3px;
            left: -2px;
            width:10px;
            height: 10px;
            position: absolute;
            border-radius: 100%;
            background-color: $player-color;
        }
    }

    .player-time {
        display: flex;
        font-size: 12px;
        justify-content: space-between;

        .player-time-current {
            font-weight: 700;
            padding-left: 5px;
        }

        .player-time-total {
            opacity: 0.5;
            padding-right: 5px;
        }
    }


</style>
